/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap");

* {
  font-family: "DM Sans", sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  line-height: normal;
  padding: 0;
  margin: 0;
  overflow: hidden;
  @apply bg-ternary-50;
}

*::-webkit-calendar-picker-indicator {
  display: none; 
  opacity: 0;
}

.search-box {
  background-image: url("./assets/icons/search.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 10px 20px 10px 40px;
}

.login-background {
  background-image: url("./assets/images/bg.png");
  background-size: cover;
}

.bi::before {
  display: inline-block;
  content: "";
  vertical-align: -0.125em;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  overflow-x: scroll;
}

/* Force the scrollbar to always be visible for elements with the class "always-visible-scrollbar" */
.always-visible-scrollbar-x {
  overflow-x: scroll;
}

/* Style the scrollbar for webkit-based browsers */
.always-visible-scrollbar-x::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 3px;
}

.always-visible-scrollbar-x::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.always-visible-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;
}

.always-visible-scrollbar-x::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1; /* Change to your preferred handle color on hover */
}

@keyframes breathe-glow {
  0%,
  100% {
    text-shadow: 0 0 1px rgb(0, 0, 0);
  }
  50% {
    text-shadow: 0 0 5px rgba(249, 232, 0, 0.623);
  }
}

.glowing-text {
  animation: breathe-glow 3s infinite;
}

.green-checkbox:checked {
  background-color: #35ce35;
  color: #34c534;
}

.ng-select.ng-select-single .ng-select-container {
  height: 2.58rem;
  border-radius: 0.375rem;
  border: 1px solid rgb(209 213 219);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 16px rgba(0,0,0,0.3)
// }

::-webkit-scrollbar-thumb {
  background-color: rgba(16, 16, 16, 0.5);
  border-radius: 0.375rem;
}

.tippy-box {
  max-width: max-content !important;
}

.ng-tooltip {
  position: absolute;
  max-width: 50vw;
  font-size: 14px;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip-text-align-center {
  text-align: center;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
